// Constants
import { QUERY_LIMITS } from '@/constants'
// Request
import { doRequest } from '@/utils/requestApi'
// utils
const JsFileDownload = require('js-file-download')

/**
 * Get clients by place ID
 *
 * @param {string} id - UID place
 * @param {object} options - options to get files from DB
 * @return {array}
 */
export async function getClientsByPlaceId(id, options = {}) {
  const { csv = false, ...params } = options
  let requestParams = {}

  // Modificamos opciones de la petición
  // para descarga de fichero CSV
  if (csv) {
    params.limit = QUERY_LIMITS[7]
    requestParams = {
      timeout: 20000,
      responseType: 'blob'
    }
  }

  const result = await doRequest({
    method: 'post',
    url: `v1/client/place/${id}`,
    data: {
      options: params,
      csv
    },
    ...requestParams
  })

  if (csv) {
    return JsFileDownload(result.data, `clients_${Date.now().toString()}.csv`)
  } else {
    return result.data
  }
}

/**
 * Get client details by place UID and Phone
 *
 * @param {string} Id - UID client documebt
 * @return {object}
 */
export async function getClientById(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/client/${id}`
  })
  return result.data
}
