// Contants
import { ORDER_STATUS, ORDER_TYPES, PAYMENT_TYPES } from '../constants'
// Utils
import { get } from 'lodash'

/**
 * Obtenemos el "payment" (etiqueta)
 *
 * @param {String} type
 * @return {String}
 */
export function orderPaymentLabel(type) {
  return get(PAYMENT_TYPES, `${type}.label`, 'No definido')
}

/**
 * Obtenemos el "color" del status
 *
 * @param {String} status
 * @return {String}
 */
export function orderStatusColor(status) {
  let statusColor = 'grey'
  switch (status) {
    case ORDER_STATUS.accepted.value:
      statusColor = 'blue'
      break
    case ORDER_STATUS.inProcess.value:
      statusColor = 'purple'
      break
    case ORDER_STATUS.sent.value:
      statusColor = 'orange'
      break
    case ORDER_STATUS.delivered.value:
      statusColor = 'light-green'
      break
    case ORDER_STATUS.rejected.value:
    case ORDER_STATUS.canceled.value:
      statusColor = 'red'
      break
    default:
      statusColor = 'grey'
  }
  return statusColor
}

/**
 * Obtenemos el "status" (etiqueta)
 *
 * @param {String} status
 * @return {String}
 */
export function orderStatusLabel(status) {
  return get(ORDER_STATUS, `${status}.label`, 'No definido')
}

/**
 * Obtenemos el "type" (etiqueta)
 *
 * @param {String} type
 * @return {String}
 */
export function orderTypesLabel(type) {
  return get(ORDER_TYPES, `${type}.label`, 'No definido')
}
