// Components
import CurrencyPosition from '@/components/ui/CurrencyPosition'
// Filters
import { formatDate } from '@/filters'
import { orderStatusColor, orderStatusLabel } from '@/addons/takeAway/filters'

export default {
  name: 'CrmCtaItem',
  components: { CurrencyPosition },
  filters: { formatDate, orderStatusColor, orderStatusLabel },
  props: {
    items: {
      required: true,
      type: Array,
      default() {
        return []
      }
    }
  }
}
