// Constants
import { ADDONS } from '@/constants'
// Components
import CardContainer from '@/components/ui/CardContainer'
import CrmCtaItem from '../../components/elements/CrmCtaItem'
import CrmOrderItem from '../../components/elements/CrmOrderItem'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
import VuetifyDataTable from '@/components/ui/VuetifyDataTable'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
import CurrencyPosition from '@/components/ui/CurrencyPosition'
// Mixins
import uiMixin from '@/mixins/uiMixin'
import addonsMixin from '@/mixins/addonsMixin'
// Filters
import { formatDate, formatPhoneNumber } from '@/filters'
// Vuex
import { mapGetters } from 'vuex'
// Services
import { getClientById } from '../../services/client'
// Utils
import { get, isNil } from 'lodash'

export default {
  name: 'CrmDetail',
  components: {
    CardContainer,
    CrmCtaItem,
    CrmOrderItem,
    CurrencyPosition,
    VuetifyContentLoading,
    VuetifyToolBar,
    VuetifyDataTable
  },
  filters: { formatDate, formatPhoneNumber },
  mixins: [uiMixin, addonsMixin],
  data() {
    return {
      // table
      ordersTableHeader: [
        {
          text: 'Pedido',
          value: 'orderId'
        },
        {
          text: 'Coste',
          value: 'cost'
        },
        {
          text: 'Fecha',
          value: 'date'
        },
        {
          text: 'Estado',
          value: 'status'
        }
      ],
      ctasTableHeader: [
        {
          text: 'Nombre',
          value: 'name'
        },
        {
          text: 'Fecha',
          value: 'date'
        },
        {
          text: 'Visualizaciones',
          value: 'visualizations'
        },
        {
          text: 'Finalizadas',
          value: 'finished'
        }
      ],
      // Others
      item: null,
      id: this.$route.params.id || null,
      processingRequest: true
    }
  },
  computed: {
    ...mapGetters('place', ['placeData']),
    /**
     * Obtenemos el pais del establecimiento
     * @return {string}
     */
    countryCodePlace() {
      return get(this.placeAddonsSetupByUser, `${ADDONS.contact}.place.country_short`, 'ES')
    },
    /**
     * Datos de "cta"
     *
     * @return {Array}
     */
    ctasData() {
      return this.item.ctas.map((cta) => {
        return {
          name: cta.name,
          date: cta.createTimestamp,
          visualizations: cta.visualizations,
          finished: cta.finished
        }
      })
    },
    /**
     * Datos de pedidos
     *
     * @return {Array}
     */
    ordersData() {
      return this.item.orders.map((order) => {
        return {
          cost: order.products
            ? order.products.reduce((sumProducts, product) => {
                sumProducts += product.price * product.quantity
                return sumProducts
              }, 0)
            : 0,
          date: order.createTimestamp,
          orderId: order.orderId,
          status: order.status
        }
      })
    },
    /**
     * Mostramos "ticket medio"
     *
     * @return {boolean}
     */
    showAverageTicket() {
      return !isNil(get(this.item, 'averageTicket', null))
    },
    /**
     * Mostramos tablas de CTA
     *
     * @return {boolean}
     */
    showCTAsDataTable() {
      const ctas = get(this.item, 'ctas', null)
      return !isNil(ctas) && Array.isArray(ctas)
    },
    /**
     * Mostramos tablas de pedidos
     *
     * @return {boolean}
     */
    showOrdersDataTable() {
      const orders = get(this.item, 'orders', null)
      return !isNil(orders) && Array.isArray(orders)
    }
  },
  async mounted() {
    // Obtenemos los datos iniciales
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * Obtenemos/establecemos todo lo necesario
     * para inicializar el componente correctamente
     */
    async getEveryNeededData() {
      try {
        // Loading
        this.processingRequest = true
        // Obtenemos detalles del cliente
        this.item = await getClientById(this.id)
      } catch (error) {
        // Mostramos error
        this.modifyAppAlert({
          type: 'error',
          text: error.message,
          visible: true
        })
      } finally {
        // Loading
        this.processingRequest = false
      }
    }
  }
}
