// Filters
import { formatDate } from '@/filters'

export default {
  name: 'CrmCtaItem',
  filters: { formatDate },
  props: {
    items: {
      required: true,
      type: Array,
      default() {
        return []
      }
    }
  }
}
