export default {
  name: 'VuetifyContentLoading',
  props: {
    label: {
      default: null,
      type: String
    },
    loading: {
      default: false,
      type: Boolean
    },
    /**
     * https://vuetifyjs.com/en/components/progress-circular/#progress-circular
     */
    loadingVOptions: {
      default() {
        return {
          size: '50',
          width: '4',
          indeterminate: true,
          color: 'secondary'
        }
      },
      type: Object
    }
  }
}
