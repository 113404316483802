export const AVERAGE_SHIPPING_TIMES = [
  {
    label: '15 minutos',
    value: 15
  },
  {
    label: '30 minutos',
    value: 30
  },
  {
    label: '45 minutos',
    value: 45
  },
  {
    label: '60 minutos',
    value: 60
  },
  {
    label: '+ de 1 hora',
    value: 0
  }
]
export const DEFAULT_REASON_REJECTION = 'No se especificó una razón'
export const PAYMENT_TYPES = {
  cash: {
    label: 'Efectivo',
    value: 'cash'
  },
  card: {
    label: 'Tarjeta física',
    value: 'card'
  }
}
export const ORDER_STATUS = {
  accepted: {
    label: 'Aceptado',
    value: 'accepted'
  },
  canceled: {
    label: 'Cancelado',
    value: 'canceled'
  },
  delivered: {
    label: 'Entregado',
    value: 'delivered'
  },
  inProcess: {
    label: 'En proceso',
    value: 'inProcess'
  },
  pending: {
    label: 'Pendiente',
    value: 'pending'
  },
  rejected: {
    label: 'Rechazado',
    value: 'rejected'
  },
  sent: {
    label: 'Enviado',
    value: 'sent'
  }
}
export const ORDER_TYPES = {
  delivery: {
    label: 'Enviar',
    value: 'delivery'
  },
  takeAway: {
    label: 'Recoger',
    value: 'takeAway'
  }
}
export const DISTANCE_UNIT_DELIVERY_AREAS = {
  kilometers: {
    label: 'Kilómetos',
    value: 'kilometers'
  },
  miles: {
    label: 'Millas',
    value: 'miles'
  }
}
export const TIME_SLOTS = [
  {
    label: '15 minutos',
    value: 15
  },
  {
    label: '30 minutos',
    value: 30
  },
  {
    label: '45 minutos',
    value: 45
  },
  {
    label: '60 minutos',
    value: 60
  }
]
