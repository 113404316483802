// Vuex
import { mapGetters } from 'vuex'
// Filters
import { formatPrice } from '@/filters'
// Utils
import { get } from 'lodash'

export default {
  name: 'CurrencyPosition',
  props: {
    price: {
      type: [Number, String],
      default: null
    },
    currency: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters('place', ['currencyData']),
    /**
     * Get the current format number by country and coin
     *
     * @return {string} - return number format prince
     */
    formatCurrencyPrice() {
      const currency = get(this.currencyData, 'code', 'EUR')
      return formatPrice(this.price, this.currency || currency)
    }
  }
}
